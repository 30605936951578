import * as requestFromServer from "./trucksCrud";
import { trucksSlice, callTypes } from "./trucksSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = trucksSlice;

export const fetchTrucks = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTrucks(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.trucksFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find trucks";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTruck = id => dispatch => {
  if (!id) {
    return dispatch(actions.truckFetched({ truckForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTruckById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const truck = response.data.truck;
      dispatch(actions.truckFetched({ truckForEdit: truck }));
    })
    .catch(error => {
      error.clientMessage = "Can't find truck";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTruck = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTruck(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.truckDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete truck";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTruck = truckForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTruck(truckForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { truck } = response.data;
      dispatch(actions.truckCreated({ truck }));
    })
    .catch(error => {
      error.clientMessage = "Can't create truck";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTruck = truck => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTruck(truck)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.truckUpdated({ truck }));
    })
    .catch(error => {
      error.clientMessage = "Can't update truck";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// //updateLocation
// router.post('/api/trucks/update-location', [authenticateToken], trucksController.updateLocation);
export const updateLocation = truck => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLocation(truck)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const updatedTruck = response.data.truck;
      dispatch(actions.truckUpdated({ truck:updatedTruck }));
    })
    .catch(error => {
      error.clientMessage = "Can't update truck";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// //updateDriver
// router.post('/api/trucks/update-driver', [authenticateToken], trucksController.updateDriver);
export const updateDriver = truck => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateDriver(truck)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const updatedTruck = response.data.truck;
      dispatch(actions.truckUpdated({ truck:updatedTruck }));
    })
    .catch(error => {
      error.clientMessage = "Can't update truck";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// //updateCurrentCapacity
// router.post('/api/trucks/update-current-capacity', [authenticateToken], trucksController.updateCurrentCapacity);
export const updateCurrentCapacity = truck => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCurrentCapacity(truck)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const updatedTruck = response.data.truck;
      dispatch(actions.truckUpdated({ truck:updatedTruck }));
    })
    .catch(error => {
      error.clientMessage = "Can't update truck";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const updateTrucksStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForTrucks(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.trucksStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update trucks status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTrucks = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTrucks(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.trucksDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete trucks";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};