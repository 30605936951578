import axios from "axios";
import { appConfigs } from "../../../../../_power_uber/_helpers";

// CREATE =>  POST: add a new order to the server
export function createOrder(order) {
  return axios.post(appConfigs.api_url + 'orders/create', { order });
}

// READ
export function getAllOrders() {
  return axios.post(appConfigs.api_url + 'orders');
}

export function getOrderById(orderId) {
  return axios.post(`${appConfigs.api_url}orders/get`, { id: orderId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrders(queryParams) {
  return axios.post(`${appConfigs.api_url}orders`, { queryParams });
}

// UPDATE => POST: update the order on the server
export function updateOrder(order) {
  return axios.post(`${appConfigs.api_url}orders/update`, { order });
}

//view order
export function viewOrder(orderId) {
  return axios.post(`${appConfigs.api_url}orders/view-order`, { orderId });
}

//assignDriver
export function assignDriver(order) {
  return axios.post(`${appConfigs.api_url}orders/assign-driver`, { order });
}

//updateLocation
export function updateLocation(order) {
  return axios.post(`${appConfigs.api_url}orders/update-location`, { order });
}

//updateStatus
export function updateStatus(order) {
  return axios.post(`${appConfigs.api_url}orders/update-status`, { order });
}

//updatePayment
export function updatePayment(order) {
  return axios.post(`${appConfigs.api_url}orders/update-payment`, { order });
}

// UPDATE Status
export function updateStatusForOrders(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForOrders`, {
    ids,
    status
  });
}

// DELETE => delete the order from the server
export function deleteOrder(orderId) {
  return axios.post(`${appConfigs.api_url}orders/delete`, { id: orderId });
}

// DELETE Orders by ids
export function deleteOrders(ids) {
  return axios.post(`${appConfigs.api_url}orders/delete-orders`, { order_ids: ids });
}


export function getTotalCapacity() {
  return axios.post(`${appConfigs.api_url}orders/get-total-capacity`);
}

export function getTotalSoldLitres() {
  return axios.post(`${appConfigs.api_url}orders/get-total-sold-litres`);
}

export function getTotalCost() {
  return axios.post(`${appConfigs.api_url}orders/get-total-cost`);
}

export function getTotalOrders() {
  return axios.post(`${appConfigs.api_url}orders/get-total-orders`);
}

export function getTotalLitresAvailable() {
  return axios.post(`${appConfigs.api_url}orders/get-total-litres-available`);
}




