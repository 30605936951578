export const appMenus = [
    {
        title: "Orders",
        icon: "/media/svg/icons/Files/Selected-file.svg",
        page: "/transfers",
        tab: "tabTransfers",
        permission: "",
        section: [
            {
                title: "Oil Orders",
                permission: "",
                submenu: [
                    {
                        title: "Dashboard",
                        icon: "/media/svg/icons/Home/Home.svg",
                        page: "/dashboard",
                        permission: "",
                    },
                    {
                        title: "Pending Driver Assignment",
                        icon: "/media/svg/icons/Code/Loading.svg",
                        page: "/order-management/orders/searching",
                        permission: "",
                    },
                    {
                        title: "Driver Going to Delivery",
                        icon: "/media/svg/icons/Code/Compiling.svg",
                        page: "/order-management/orders/enroute",
                        permission: "",
                    },
                    {
                        title: "Pending Payment Confirmation",
                        icon: "/media/svg/icons/Code/Lock-overturning.svg",
                        page: "/order-management/orders/arrived",
                        permission: "",
                    },
                    {
                        title: "Completed Orders",
                        icon: "/media/svg/icons/Code/Done-circle.svg",
                        page: "/order-management/orders/completed",
                        permission: "",
                    },
                    {
                        title: "Cancelled Orders",
                        icon: "/media/svg/icons/Code/Error-circle.svg",
                        page: "/order-management/orders/cancelled",
                        permission: "",
                    },
                    {
                        title: "Failed Orders",
                        icon: "/media/svg/icons/Code/Thunder-circle.svg",
                        page: "/order-management/orders/failed",
                        permission: "",
                    },
                    {
                        title: "All Orders",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/order-management",
                        permission: "",
                    },
                ],
            },
            
        ],
    },
    {
        title: "User Management",
        icon: "/media/svg/icons/Communication/Group.svg",
        page: "/user-management",
        tab: "tabUsers",
        permission: "accessToUsersRead",
        section: [
            {
                title: "User Management",
                permission: "accessToUsersRead",
                submenu: [
                    {
                        title: "Manage Users",
                        icon: "/media/svg/icons/Communication/Group.svg",
                        page: "/user-management/users",
                        permission: "accessToUsersRead",
                    },
                    {
                        title: "Manage Roles",
                        icon: "/media/svg/icons/Code/Compiling.svg",
                        page: "/user-management/roles",
                        permission: "accessToRolesRead",
                    },
                ],
            },
        ],
    },
    {
        title: "Settings",
        icon: "/media/svg/icons/General/Settings-1.svg",
        page: "/settings",
        tab: "tabSettings",
        permission: "accessToSettingsRead",
        section : [
            {
                title: "Settings",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "View Settings",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/settings/view",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Update Settings",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/settings/update",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
            {
                title: "Manage Customers",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Manage Customers",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/customer-management/customers",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
            {
                title: "Manage Drivers",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Manage Drivers",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/driver-management/drivers",
                        permission: "",
                    },
                ],
            },
            {
                title: "Manage Trucks",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Manage Trucks",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/truck-management/trucks",
                        permission: "",
                    },
                ],
            },
            {
                title: "Manage Products",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Manage Products",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/product-management/products",
                        permission: "",
                    },
                ],
            },
        ],
    }
];