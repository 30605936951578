import React from 'react';
import {  Cell, Pie, PieChart } from 'recharts';

export function CustomPieChart({
  chartData = [],
  chartColor = '#37ad47',
  dataKey = 'total',
  dataName = 'Total',
  xAxisField = 'name',
}) {
    const data = chartData || [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
      ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    //const RADIAN = Math.PI / 180;
    // const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    //     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    //     const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //     const y = cy + radius * Math.sin(-midAngle * RADIAN);

    //     return (
    //         <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    //         {`${(percent * 100).toFixed(0)}%`}
    //         </text>
    //     );
    // };

    const renderLabel = function(entry) {
        return `${entry.name} (${entry.value})`;
    }

  return (
    <div>
        <PieChart width={400} height={400}  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            // label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={renderLabel}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>

 
    </div>
  );
}

