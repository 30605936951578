import * as requestFromServer from "./rolesCrud";
import { rolesSlice, callTypes } from "./rolesSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = rolesSlice;

export const fetchRoles = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findRoles(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.rolesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find roles";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRole = id => dispatch => {
  if (!id) {
    return dispatch(actions.roleFetched({ roleForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRoleById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const role = response.data.role;
      dispatch(actions.roleFetched({ roleForEdit: role }));
    })
    .catch(error => {
      error.clientMessage = "Can't find role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRole = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRole(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.roleDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createRole = roleForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRole(roleForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { role } = response.data;
      dispatch(actions.roleCreated({ role }));
    })
    .catch(error => {
      error.clientMessage = "Can't create role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRole = role => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRole(role)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.roleUpdated({ role }));
    })
    .catch(error => {
      error.clientMessage = "Can't update role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRolesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForRoles(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.rolesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update roles status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRoles = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRoles(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.rolesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete roles";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
