import {createSlice} from "@reduxjs/toolkit";

const initialTrucksState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  truckForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const trucksSlice = createSlice({
  name: "trucks",
  initialState: initialTrucksState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTruckById
    truckFetched: (state, action) => {
      state.actionsLoading = false;
      state.truckForEdit = action.payload.truckForEdit;
      state.error = null;
    },
    // findTrucks
    trucksFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTruck
    truckCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.truck !== null){
        var usr = action.payload.truck;
        state.entities.push(usr);
      }
    },
    // updateTruck
    truckUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.truck.id) {
          return action.payload.truck;
        }
        return entity;
      });
    },
    // deleteTruck
    truckDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTrucks
    trucksDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // trucksUpdateState
    trucksStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
