export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  //add 401 response interceptor
  // axios.interceptors.response.use(
  //   response => response,
  //   error => {
  //     if (error.response.status === 401) {
  //       store.dispatch({
  //         type: "Logout"
  //       });
  //     }
  //     return Promise.reject(error);
  //   }
  // );
}
