export const appPermissions = {
    permissions: [
        //==================================
        {
            id: "MTA_1",
            name: 'accessToUsers',
            level: 1,
            title: 'Manage Users'
        },
        //----------------------------------
        {
            id: "MTA_1a",
            name: 'accessToUsersRead',
            level: 2,
            parentId: "MTA_1",
            title: 'Read'
        },
        {
            id: "MTA_1b",
            name: 'accessToUsersEdit',
            level: 2,
            parentId: "MTA_1",
            title: 'Edit'
        },
        {
            id: "MTA_1c",
            name: 'accessToUsersDelete',
            level: 2,
            parentId: "MTA_1",
            title: 'Delete'
        },
        //----------------------------------
        //==================================


        //==================================
        {
            id: "MTA_2",
            name: 'accessToRoles',
            level: 1,
            title: 'Manage User Roles & Permissions'
        },
        //----------------------------------
        {
            id: "MTA_2a",
            name: 'accessToRolesRead',
            level: 2,
            parentId: "MTA_2",
            title: 'Read'
        },
        {
            id: "MTA_2b",
            name: 'accessToRolesEdit',
            level: 2,
            parentId: "MTA_2",
            title: 'Edit'
        },
        {
            id: "MTA_2c",
            name: 'accessToRolesDelete',
            level: 2,
            parentId: "MTA_2",
            title: 'Delete'
        },
        //----------------------------------
        //==================================


        //==================================
        {
            id: "MTA_3",
            name: 'accessToTransactionsRead',
            level: 1,
            title: 'Manage Transactions'
        },
        //----------------------------------
        {
            id: "MTA_3a",
            name: 'accessToTransactionsCreate',
            level: 2,
            parentId: "MTA_3",
            title: 'Create'
        },
        {
            id: "MTA_3b",
            name: 'accessToTransactionsEdit',
            level: 2,
            parentId: "MTA_3",
            title: 'Edit'
        },
        {
            id: "MTA_3c",
            name: 'accessToTransactionsDelete',
            level: 2,
            parentId: "MTA_3",
            title: 'Delete'
        },
        {
            id: "MTA_3d",
            name: 'accessToTransactionsApprove',
            level: 2,
            parentId: "MTA_3",
            title: 'Approve'
        },
        {
            id: "MTA_3e",
            name: 'accessToTransactionsCancel',
            level: 2,
            parentId: "MTA_3",
            title: 'Cancel'
        },
        {
            id: "MTA_3f",
            name: 'accessToTransactionsConfirm',
            level: 2,
            parentId: "MTA_3",
            title: 'Confirm'
        },
        {
            id: "MTA_3g",
            name: 'accessToTransactionsView',
            level: 2,
            parentId: "MTA_3",
            title: 'View'
        },
        {
            id: "MTA_3h",
            name: 'accessToTransactionsDeposit',
            level: 2,
            parentId: "MTA_3",
            title: 'Deposit'
        },
        {
            id: "MTA_3i",
            name: 'accessToTransactionsRetry',
            level: 2,
            parentId: "MTA_3",
            title: 'Retry Transaction'
        },
        //----------------------------------
        //==================================


        //==================================
        {
            id: "MTA_4",
            name: 'accessToReportsRead',
            level: 1,
            title: 'Manage Reports'
        },
        //----------------------------------
        {
            id: "MTA_4a",
            name: 'accessToReportsWrite',
            level: 2,
            parentId: "MTA_4",
            title: 'Write'
        },
        {
            id: "MTA_4b",
            name: 'accessToReportsRead',
            level: 2,
            parentId: "MTA_4",
            title: 'Read'
        },
        //----------------------------------

        //==================================
        {
            id: "MTA_6",
            name: 'accessToSettings',
            level: 1,
            title: 'Manage System Settings'
        },
        //----------------------------------
        {
            id: "MTA_6a",
            name: 'accessToSettingsRead',
            level: 2,
            parentId: "MTA_6",
            title: 'Read'
        },
        {
            id: "MTA_6b",
            name: 'accessToSettingsEdit',
            level: 2,
            parentId: "MTA_6",
            title: 'Edit'
        },
        //----------------------------------
        //==================================

    ]
};