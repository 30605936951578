import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {rolesSlice} from "../app/modules/UserManagement/_redux/roles/rolesSlice";
import {usersSlice} from "../app/modules/UserManagement/_redux/users/usersSlice";
import {customersSlice} from "../app/modules/Settings/_redux/customers/customersSlice";
import { driversSlice } from "../app/modules/Settings/_redux/drivers/driversSlice";
import { trucksSlice } from "../app/modules/Settings/_redux/trucks/trucksSlice";
import { ordersSlice } from "../app/modules/Settings/_redux/orders/ordersSlice";
import {settingsSlice}from "../app/modules/Settings/_redux/system/settingsSlice";
import {productsSlice} from "../app/modules/Settings/_redux/products/productsSlice";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  roles: rolesSlice.reducer,
  users: usersSlice.reducer,
  settings: settingsSlice.reducer,
  customers: customersSlice.reducer,
  drivers: driversSlice.reducer,
  trucks: trucksSlice.reducer,
  orders: ordersSlice.reducer,
  products: productsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
