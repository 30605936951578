import React, { useEffect } from "react";//
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as actions from "../modules/Settings/_redux//orders/ordersActions";
import * as truckActions from "../modules/Settings/_redux/trucks/trucksActions";
import { Alert } from "react-bootstrap";
import {CustomBarChart, TilesWidget11 } from "../../_power_uber/_partials/widgets";//,CustomBarChart
import { Maps } from "../widgets/Maps";
import { CustomPieChart } from "../../_power_uber/_partials/widgets/tiles/CustomPieChart";

export function DashboardPage() {
    

    const { user_id,first_name,totalCapacity, totalSoldLitres, totalCost, totalOrders, totalLitresAvailable } = useSelector(
        (state) => ({
            user_id: state.auth.user.id || "",
            first_name: state.auth.user.first_name || "",

            totalCapacity: state.orders.totalCapacity || 0,
            totalSoldLitres: state.orders.totalSoldLitres || 0,
            totalCost: state.orders.totalCost || 0,
            totalOrders: state.orders.totalOrders || 0,
            totalLitresAvailable: state.orders.totalLitresAvailable || 0,

        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    useEffect(() => {
        

        dispatch(actions.getTotalCapacity({}));
        dispatch(actions.getTotalSoldLitres({}));
        dispatch(actions.getTotalCost({}));
        dispatch(actions.getTotalOrders({}));
        dispatch(actions.getTotalLitresAvailable({}));


        dispatch(truckActions.fetchTrucks());

        // eslint-disable-next-line 
    }, [dispatch, user_id]);

    //function to display a HTML grid of 3 by two

    return (<>
        <div className="row">
            <div className="col-xl-12">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <h2>Link Energy - <em>Fuel Distributors</em></h2>
                            <Alert variant="primary"> Welcome {first_name}!</Alert>
                        </div>
                    </div>
                </div>
            </div>
    
           
            <div className="col-xl-4">
                <TilesWidget11 className="card-stretch gutter-b" baseColor="primary" value={totalCapacity} label="Total Capacity" />
            </div>
            <div className="col-xl-4">
                <TilesWidget11 className="card-stretch gutter-b" baseColor="info" value={totalSoldLitres.toFixed(2)} label="Total Litres Sold" />
            </div>
            <div className="col-xl-4">
                <TilesWidget11 className="card-stretch gutter-b" baseColor="success" value={totalCost.toFixed(2)} label="Total Payments (USD)" />
            </div>

            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">Fuel Sales</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <CustomBarChart dataName="Oil Sales" chartColor="#FE7E51" chartData={[ { name: 'January', total: 2400 },
                            { name: 'February', total: 2210 },
                            { name: 'March', total: 2290 },
                            { name: 'April', total: 2000 },
                            { name: 'May', total: 2181 },
                            { name: 'June', total: 2500 },
                            { name: 'July', total: 2100 }]} />
                    </div>
                </div>  
            </div>
            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">Fuel Sales</h3>
                        </div>
                    </div>
                    <div className="card-body">
                    <CustomPieChart chartData={[ { name: 'Group A', value: 400 },
                        { name: 'Group B', value: 300 },
                        { name: 'Group C', value: 300 },
                        { name: 'Group D', value: 200 }]} />
                    </div>
                </div>  
            </div>

            

            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">Revenues</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <CustomBarChart dataName="Revenues" chartColor="#0288d1" chartData={[ { name: 'January', total: 2400 },
                            { name: 'February', total: 2210 },
                            { name: 'March', total: 2290 },
                            { name: 'April', total: 2000 },
                            { name: 'May', total: 2181 },
                            { name: 'June', total: 2500 },
                            { name: 'July', total: 2100 }]} />
                    </div>
                </div>  
            </div>
                            
            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">Fuel Sales</h3>
                        </div>
                    </div>
                    <div className="card-body">
                    <CustomPieChart chartData={[ { name: 'Group A', value: 400 },
                        { name: 'Group B', value: 300 },
                        { name: 'Group C', value: 300 },
                        { name: 'Group D', value: 200 }]} />
                    </div>
                </div>  
            </div>
           
            <div className="col-xl-6">
                <TilesWidget11 className="card-stretch gutter-b" baseColor="info" value={totalOrders} label="Total Orders Made" />
            </div>
            <div className="col-xl-6">
                <TilesWidget11 className="card-stretch gutter-b" baseColor="primary" value={totalLitresAvailable.toFixed(2)} label="Total Unsold Fuel (ltrs)" />
            </div>
   
            <div className="col-xl-12 mb-5">
                <Maps />
            </div>
        </div>
    </>);
}
