import axios from "axios";
import { appConfigs } from "../../../../../_power_uber/_helpers";

// CREATE =>  POST: add a new truck to the server
export function createTruck(truck) {
  return axios.post(appConfigs.api_url + 'trucks/create', { truck });
}

// READ
export function getAllTrucks() {
  return axios.post(appConfigs.api_url + 'trucks');
}

export function getTruckById(truckId) {
  return axios.post(`${appConfigs.api_url}trucks/get`, { id: truckId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findTrucks(queryParams) {
  return axios.post(`${appConfigs.api_url}trucks`, { queryParams });
}

// UPDATE => POST: update the truck on the server
export function updateTruck(truck) {
  return axios.post(`${appConfigs.api_url}trucks/update`, { truck });
}


export function updateLocation(truck) {
  return axios.post(`${appConfigs.api_url}trucks/update-location`, { truck });
}
export function updateDriver(truck) {
  return axios.post(`${appConfigs.api_url}trucks/update-driver`, { truck });
}
export function updateCurrentCapacity(truck) {
  return axios.post(`${appConfigs.api_url}trucks/update-current-capacity`, { truck });
}

// UPDATE Status
export function updateStatusForTrucks(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForTrucks`, {
    ids,
    status
  });
}

// DELETE => delete the truck from the server
export function deleteTruck(truckId) {
  return axios.post(`${appConfigs.api_url}trucks/delete`, { id: truckId });
}

// DELETE Trucks by ids
export function deleteTrucks(ids) {
  return axios.post(`${appConfigs.api_url}trucks/delete-trucks`, { truck_ids: ids });
}
