import * as requestFromServer from "./ordersCrud";
import { ordersSlice, callTypes } from "./ordersSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = ordersSlice;

export const fetchOrders = queryParams => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer
      .findOrders(queryParams);
    if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
    const { totalCount, entities } = response.data;
    dispatch(actions.ordersFetched({ totalCount, entities }));
  } catch (error) {
    error.clientMessage = "Can't find orders";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchOrder = id => async dispatch => {
  if (!id) {
    return dispatch(actions.orderFetched({ orderForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const order = response.data.order;
      dispatch(actions.orderFetched({ orderForEdit: order }));
    })
    .catch(error => {
      error.clientMessage = "Can't find order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//viewOrder
export const viewOrder = order => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .viewOrder(order)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const order = response.data.order;
      dispatch(actions.orderViewFetched({ orderForView: order }));
    })
    .catch(error => {
      error.clientMessage = "Can't find order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrder = id => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrder(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.orderDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrder = orderForCreation => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrder(orderForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { order } = response.data;
      dispatch(actions.orderCreated({ order }));
    })
    .catch(error => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrder = order => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrder(order)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.orderUpdated({ order }));
    })
    .catch(error => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


// //assignDriver
// router.post('/api/orders/assign-driver', [authenticateToken], ordersController.assignDriver);

export const assignDriver = order => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .assignDriver(order)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const updatedOrder = response.data.order;
      dispatch(actions.orderUpdated({ order:updatedOrder }));
    })
    .catch(error => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// //updateLocation
// router.post('/api/orders/update-location', [authenticateToken], ordersController.updateLocation);
export const updateLocation = order => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLocation(order)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const updatedOrder = response.data.order;
      dispatch(actions.orderUpdated({ order:updatedOrder }));
    })
    .catch(error => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// //updateStatus
// router.post('/api/orders/update-status', [authenticateToken], ordersController.updateStatus);
export const updateStatus = order => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatus(order)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const updatedOrder = response.data.order;
      dispatch(actions.orderUpdated({ order:updatedOrder }));
    })
    .catch(error => {
      error.clientMessage = "Can't update order status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// //updatePayment
// router.post('/api/orders/update-payment', [authenticateToken], ordersController.updatePayment);
export const updatePayment = order => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePayment(order)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const updatedOrder = response.data.order;
      dispatch(actions.orderUpdated({ order:updatedOrder }));
    })
    .catch(error => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrdersStatus = (ids, status) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOrders(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.ordersStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update orders status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrders = ids => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrders(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.ordersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete orders";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getTotalCapacity = () => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTotalCapacity()
    .then(response => {
      const totalCapacity = response.data.totalCapacity;
      dispatch(actions.totalCapacityFetched({ totalCapacity }));
    })
    .catch(error => {
      error.clientMessage = "Can't get total capacity";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const getTotalSoldLitres = () => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTotalSoldLitres()
    .then(response => {
      const totalSoldLitres = response.data.totalLitres;
      dispatch(actions.totalSoldLitresFetched({ totalSoldLitres }));
    })
    .catch(error => {
      error.clientMessage = "Can't get total sold litres";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}


export const getTotalCost = () => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTotalCost()
    .then(response => {
      const totalCost = response.data.totalCost;
      dispatch(actions.totalCostFetched({ totalCost }));
    })
    .catch(error => {
      error.clientMessage = "Can't get total cost";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}


export const getTotalOrders = () => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTotalOrders()
    .then(response => {
      const totalOrders = response.data.totalOrders;
      dispatch(actions.totalOrdersFetched({ totalOrders }));
    })
    .catch(error => {
      error.clientMessage = "Can't get total orders";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const getTotalLitresAvailable = () => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTotalLitresAvailable()
    .then(response => {
      const totalLitresAvailable = response.data.totalLitresAvailable;
      dispatch(actions.totalLitresAvailableFetched({ totalLitresAvailable }));
    })
    .catch(error => {
      error.clientMessage = "Can't get total litres available";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}
