import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import { Card } from 'react-bootstrap';
import { shallowEqual, useSelector } from "react-redux";

export function Maps() {
  React.useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }, []);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.trucks }),
    shallowEqual
  );

  const { entities:trucks } = currentState;

  return (
    <Card variant="primary" className="mt-4">
      <Card.Body>
        <Card.Title>Below are your Truck Locations</Card.Title>
        <MapContainer center={[-11.6737554034334, 27.48785121303428]} zoom={7} style={{ height: "70vh", border: "2px solid #223388" }}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {trucks && trucks.length > 0 && trucks.map((truck, index) => {
            return (
              <Marker key={index} position={[(!isNaN(truck.lat) ? truck.lat : -11.6737554034334), (!isNaN(truck.long) ? truck.long : 27.48785121303428)]}>
                <Popup>
                  <strong>{truck.registrationNumber} | {truck.currentCapacity}/{truck.capacity}</strong>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </Card.Body>
    </Card>
  );
};