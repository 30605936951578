import {createSlice} from "@reduxjs/toolkit";

const initialOrdersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  orderForEdit: undefined,
  orderForView: undefined,
  lastError: null,
  totalCapacity: 0,
  totalLitres: 0,
  totalSoldLitres: 0,
  totalCost: 0,
  totalOrders: 0,
  totalLitresAvailable: 0
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getOrderById
    orderFetched: (state, action) => {
      state.actionsLoading = false;
      state.orderForEdit = action.payload.orderForEdit;
      state.error = null;
    },
    orderViewFetched: (state, action) => {
      state.actionsLoading = false;
      state.orderForView = action.payload.orderForView;
      state.error = null;
    },
    // findOrders
    ordersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createOrder
    orderCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.order !== null){
        var usr = action.payload.order;
        state.entities.push(usr);
      }
    },
    // updateOrder
    orderUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.order.id) {
          return action.payload.order;
        }
        return entity;
      });
    },
    // deleteOrder
    orderDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteOrders
    ordersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // ordersUpdateState
    ordersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //totalCapacityFetched
    totalCapacityFetched: (state, action) => {
      state.totalCapacity = action.payload.totalCapacity;
    },
    //totalLitresFetched
    totalLitresFetched: (state, action) => {
      state.totalLitres = action.payload.totalLitres;
    },
    //totalSoldLitresFetched
    totalSoldLitresFetched: (state, action) => {
      state.totalSoldLitres = action.payload.totalSoldLitres;
    },
    //totalCostFetched
    totalCostFetched: (state, action) => {
      state.totalCost = action.payload.totalCost;
    },
    //totalOrdersFetched
    totalOrdersFetched: (state, action) => {
      state.totalOrders = action.payload.totalOrders;
    },
    //totalLitresAvailableFetched
    totalLitresAvailableFetched: (state, action) => {
      state.totalLitresAvailable = action.payload.totalLitresAvailable;
    }
    

  }
});
